import React, { useContext, useRef }  from "react";
import { useIntl } from "gatsby-plugin-react-intl";

import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import ReactLogo from "../static/lchtgst_logo_only_black_paths.svg";

const About = () => {

    const pageEnd = useRef(null);
    const intl = useIntl();

    const srcSetSizeXS = 480;
    const srcSetSizeS = 600;
    const srcSetSizeM = 960;
    const srcSetSizeL = 1400;

    const ContextAwareToggle = ({ children, eventKey, callback }) => {

        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        
        const isCurrentEventKey = activeEventKey === eventKey;
        
        return (
            <button
                style={{border: 'none', padding: '0'}}
                onClick={() => {decoratedOnClick(); setTimeout(function(){pageEnd.current.scrollIntoView({ behavior: "smooth", block: "end" });}, 175);}}
                className="aboutReferencesToggle"
            >
                <div className="aboutLogoToggleContainer">
                    <ReactLogo 
                    //className="aboutReferencesLogo logo-clicked"
                    className={`aboutReferencesLogo ${isCurrentEventKey ? 'logo-clicked' : 'logo-notClicked'}`}
                    //style={{backgroundColor: isCurrentEventKey ? 'wheat' : 'white'}}
                    />
                </div>
                {children}
            </button>
        );
    }

    return (
        <div className="about h-100">
            <div className="row h-100">
                <div className="col-xl-6 col-lg-24 aboutText">
                    <div className="aboutLogoContainer">
                        <div className="aboutTitleLogo">
                            <ReactLogo className="aboutLogo"/>
                        </div>
                        <div className="aboutTitleHeader">
                            <h1>
                                {intl.formatMessage({ id: "about" })}
                            </h1>
                        </div>
                    </div>
                    <p className="aboutLeftContent">
                        {intl.formatMessage({ id: "aboutBio" })}
                    </p>
                </div>
                <div className="col-xl-12 col-lg-24 aboutImage">
                    <img 
                        className="img-fluid aboutImageImg" 
                        src={'https://lichtgst.uber.space/photos/about/potrait_lichtgestalten_2014_' + `${srcSetSizeM}` + '.jpg' + ' ' + `${srcSetSizeM}` + "w, "}
                        srcSet={
                            'https://lichtgst.uber.space/photos/about/potrait_lichtgestalten_2014_' + `${srcSetSizeL}` + '.jpg' + ' ' + `${srcSetSizeL}` + "w, " +
                            'https://lichtgst.uber.space/photos/about/potrait_lichtgestalten_2014_' + `${srcSetSizeM}` + '.jpg' + ' ' + `${srcSetSizeM}` + "w, " +
                            'https://lichtgst.uber.space/photos/about/potrait_lichtgestalten_2014_' + `${srcSetSizeS}` + '.jpg' + ' ' + `${srcSetSizeS}` + "w, " +
                            'https://lichtgst.uber.space/photos/about/potrait_lichtgestalten_2014_' + `${srcSetSizeXS}` + '.jpg' + ' ' + `${srcSetSizeXS}` + "w"
                          }
                        sizes="100vw"
                        alt="lichtgestalten Portrait">
                    </img>
                </div>
                <div className="col-xl-6 col-lg-24 aboutReferences">
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <ContextAwareToggle eventKey="0">
                                    <h1 className="aboutH1Card">
                                        {intl.formatMessage({ id: "aboutActs" })}
                                    </h1>
                                </ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="aboutCardBody">RIN, Bilderbuch, Mount Kimbie, Bonaparte, Jan Delay, Dominik Eulberg, HONNE, Son Lux, Prosumer & ND_Baumecker, Move D, Kerri Chandler, dOp live, Chris Liebing, Marc Romboy, Stephan Bodzin, Kollektiv Turmstrasse, Sascha Funke, Moonbootica, Ellen Allien, Pantha Du Prince, Pupkulies & Rebecca, Johannes Heil, Soul Clap, Aka Aka feat. Thalstroem, Kaytranada</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <ContextAwareToggle eventKey="1">
                                    <h1 className="aboutH1Card">festivals</h1>
                                </ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body className="aboutCardBody">MS Dockville, Frauenfeld, Splash!, Frequency, Stuttgart Festival, MLIVE, Spektrum, Plötzlich Am Meer, Marienplatzfest, Open Bird, Aromåt, Beyond Festival Karlsruhe, Lange Nacht Der Museen Böblingen</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <ContextAwareToggle eventKey="2">
                                    <h1 className="aboutH1Card">locations</h1>
                                </ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body className="aboutCardBody">Arsenale di Venezia (Venedig), Palais de Tokyo (Paris), Deutsches Museum (München), Allianz Arena (München), Zentrum für Kunst und Medientechnologie (Karslruhe), Schlachthof (Wiesbaden), Wagenhallen (Stuttgart), Malzfabrik (Berlin), Kantine (Ravensburg), Rocker33 (Stuttgart), Kowalski (Stuttgart), Junges Ensemble (Stuttgart), Staatstheater Stuttgart, Berghain Kantine (Berlin), Messe Stuttgart</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <div ref={pageEnd} />
                </div>
            </div>
        </div>
    );
};

export default About;