import React from "react"

import Layout from "../components/layout"
import About from "../components/about"
import SEO from "../components/seo"

import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl";

const IndexPage = ({intl}) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: "titleAbout" })} lang={intl.locale}/>
    <About></About>
  </Layout>
)

export default injectIntl(IndexPage)
